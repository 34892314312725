$enable-validation-icons: false;
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~datatables.net-bs4/css/dataTables.bootstrap4';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4';
@import '~bootstrap-switch/dist/css/bootstrap3/bootstrap-switch';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker';
@import 'file_listing';
@import "error_correction";
@import 'registration_form';
@import 'datatable';

body {
  margin-bottom: 200px;

  #search-inp {
    width: 300px;
  }
}

.popover {
  max-width: none;
}

footer.fixed-bottom {
  z-index: $zindex-dropdown - 1;
}

button.tipsy {
  background-color: $input-border-color;
}

table.table.table-bordered-around {
  border: 1px solid rgba(0, 0, 0, .125);
}

label abbr {
  color: red;
}

.required {
  font-weight: bold;
}

.layoutFooterLink, .layoutFooterLink:hover{
  color: inherit;
  text-decoration: none;
}

.no-close-button {
  button.close {
    display: none;
  }
}

.form_box {
  border: 1px solid;
  padding: 14px;
  border-radius: 5px;
}

.explanation {
  font-size: 14px;
}
