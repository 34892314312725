.side-by-side{
  margin-right: 8px;
}

.error-correction-info {
  > span:not(:last-child) {
    border-right: 2px solid #6c757d;
    padding-right: 5px;
  }
}
