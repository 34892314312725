.file-listing-index {
  .popover-body {
    td:first-child {
      padding-right: 10px;
    }
  }
}
.modal-body {
  word-break: break-word;
}

