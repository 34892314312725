.registrations-create, .registrations-new {
  .registration_pmps.form-group-invalid {
    label {
      color: $red;  
    }
    .invalid-feedback {
      font-size: 100%;
    }
  }
}
