.dataTables_processing{
  display: none !important;
}

// sorting arrows
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  margin-right: 5px;
  font-size: large !important;
  content: "\2191" !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  margin-top: -2px;
  font-size: large !important;
  content: "\2193" !important;
}
// end sorting arrows
